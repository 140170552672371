import type { ISubscription, IConfirmSubscription } from '@types';

export const useSubscription = () => {
  const localePath = useLocalePath();
  const { user } = storeToRefs(useUserStore());

  const pending = ref<boolean>(false);
  const isSuccessSubscription = ref<boolean>(false);

  const fetchGuestNewsletter = async (data: ISubscription): Promise<void> => {
    try {
      await fetchApiPost('/newsletter/guest', {
        data,
      });
    } catch (error: any) {
      throw createError(error);
    }
  };

  const fetchUserNewsletter = async (data: ISubscription): Promise<void> => {
    try {
      const { subscribeNewsletter } = await fetchApiPost('/newsletter/user', {
        data,
      });

      if (subscribeNewsletter) {
        user.value.email = subscribeNewsletter.email;
        user.value.isSubscribed = subscribeNewsletter.isSubscribed;
      }
    } catch (error: any) {
      throw createError(error);
    }
  };

  const fetchSubscription = async (data: IConfirmSubscription): Promise<void> => {
    try {
      const { isSubscribed } = await fetchApiPost('/newsletter/subscription', {
        data,
      });

      if (user.value) {
        user.value.isSubscribed = isSubscribed;
      }

      if (isSubscribed) {
        navigateTo({ path: localePath('/newsletter/success'), replace: true });
      }
    } catch (error: any) {
      throw createError(error);
    }
  };

  return {
    pending,
    isSuccessSubscription,
    fetchSubscription,
    fetchUserNewsletter,
    fetchGuestNewsletter,
  };
};
